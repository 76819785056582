type ColorBadgeType =
    | "Maschinenbau"
    | "Wirtschaftswissenschaften"
    | "Geisteswissenschaften"
    | "Naturwissenschaften"
    | "Bauingenieurwesen"
    | "Elektrotechnik & IT"

export default function ColorBadges({
    colorBadges,
    activeColorBadges,
}: {
    colorBadges: {
        name: string
        icon: {
            alt: string
            positionX?: number
            positionY?: number
            src: string
            srcSet?: string
        }
    }[]
    activeColorBadges: ColorBadgeType[]
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "0.5em",
            }}
        >
            {colorBadges.map(({ name, icon }) => (
                <div
                    style={{
                        display: "grid",
                        justifyItems: "center",
                        borderRadius: "0.5em",
                        overflow: "hidden",
                    }}
                    title={name}
                    key={name}
                >
                    <img
                        style={{
                            width: "2.5em",
                            filter: activeColorBadges.includes(name)
                                ? "none"
                                : "grayscale(100%) opacity(30%)",
                        }}
                        {...icon}
                    />
                </div>
            ))}
        </div>
    )
}
